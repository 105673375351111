html,
body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
		"Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* width */
::-webkit-scrollbar {
	width: 2px !important;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f107;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #f1f1f124;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.loginlayout {
	background: radial-gradient(50% 50% at 50% 50%, #63386a 0%, #462780 100%) !important;
	background-size: "cover";
}

.logincontainer {
	max-width: 408px;
	margin: auto;
}

.logintitle {
	text-align: center;
	color: #626262;
	font-size: 30px;
	letter-spacing: -0.04em;
}

.loginimageContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
}

.ant-layout-sider-trigger {
	height: 30px !important;
	line-height: 30px !important;
}

.page-title-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 8px;
	margin-bottom: 16px !important;
	padding: 10px;
	background-color: #000000;
}

.page-title {
	font-size: 20px;
}

.card-list {
	padding-left: 4px !important;
	padding-right: 4px !important;
	height: 100%;
}

.flex {
	display: flex;
}

.gap-10 {
	gap: 10px;
}

.card-flex-main {
	display: flex;
	align-items: center;
	gap: 8px;
}

.card-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.card-title {
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
}

.card-search {
	background-color: #13131a;
	border-radius: 18px;
	margin-bottom: 0px !important;
}

.card-container .ant-card-body {
	padding: 12px !important;
}

.search-input {
	border: none !important;
}

.card-icon-btn {
	background: #13131a !important;
	margin: 0px 5px;
	border-radius: 22px !important;
	border: none !important;
}

.card-label {
	color: #ffffff !important;
	opacity: 0.6;
	padding-right: 10px;
}

.card-text {
	color: #ffffff !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.txt-uppercase {
	text-transform: uppercase;
}

.txt-capitalize {
	text-transform: capitalize;
}

.icon-pointer {
	cursor: pointer;
	color: #462780;
}



.btn-warning {
	padding: 4px 6px !important;
}

.btn-delete {
	border: 2px solid #fc5a5a94 !important;
	color: #fc5a5a !important;
	padding: 4px 8px !important;
}

.btn-rollback {
	border: 2px solid #ff8c0096 !important;
	color: #ff8c00 !important;
	padding: 4px 8px !important;
}

.radio-btn-main {
	background-color: #13131a;
	border-radius: 18px;
}

.radio-btn-main>.ant-radio-button-wrapper::before {
	background-color: transparent !important;
}

.radio-btn-main>.ant-radio-button-wrapper {
	border: 0 !important;
	border-radius: 18px !important;
}

/*Margin Padding Styles*/

.pb-5 {
	padding-bottom: 5px !important;
}

.pb-10 {
	padding-bottom: 10px !important;
}

.pb-15 {
	padding-bottom: 15px !important;
}

.pr-5 {
	padding-right: 5px !important;
}

.pr-10 {
	padding-right: 10px !important;
}

.pl-5 {
	padding-left: 5px !important;
}

.p-0 {
	padding: 0px !important;
}

.p-10 {
	padding: 10px !important;
}

.m-10 {
	margin: 10px !important;
}

.ml-5 {
	margin-left: 5px !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.ml-15 {
	margin-left: 15px !important;
}

.ml-20 {
	margin-left: 20px !important;
}

.mr-5 {
	margin-right: 5px !important;
}

.mr-10 {
	margin-right: 10px !important;
}

.mr-15 {
	margin-right: 15px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-8 {
	margin-bottom: 10px !important;
}

.mb-0 {
	margin-bottom: 0px !important;
}

.mt-5 {
	margin-top: 5px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

/*Margin Padding Styles END */

/*  Flex */
.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.justify-between {
	justify-content: space-between !important;
}

.justify-center {
	justify-content: center !important;
	align-items: center !important;
}

/* Flex End */

.card-grey {
	background-color: #dfe9ee7a !important;
	border-radius: 10px !important;
	margin-bottom: 0px;
	padding: 15px;
}

.card-white {
	background-color: #ffffff !important;
	border-radius: 10px !important;
	margin-bottom: 0px;
	padding: 15px;
	width: 100%;
}

.btn-block-dark {
	background-color: rgb(94, 94, 94) !important;
	border-radius: 6px !important;
	margin-bottom: 10px !important;
	padding: 4px 8px !important;
	color: #ffffff !important;
	font-size: 14px !important;
	width: 33.3%;
	margin: 3px;
}

.btn-block-blue {
	background-color: #41c4f1 !important;
	border-radius: 6px !important;
	margin-bottom: 10px !important;
	padding: 4px 8px !important;
	color: #ffffff !important;
	font-size: 14px !important;
	width: 33.3%;
	margin: 3px;
}

.Card-small {
	background-color: #e4e6ef !important;
	border-radius: 15px !important;
	margin: 10px 0px;
	padding: 15px 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* cursor: pointer; */
	position: relative;
}

.Card-small1 {
	padding: 95px 8px !important;
	background-color: unset !important
}

.Card-small2 {
	background-color: unset !important;
	padding: 15px 8px !important;
	border-left: 1px solid #e3e3e3;
	height: 89px;
	position: relative;
}

.Card-small2 {
	border-left: 1px solid #e3e3e3;
	height: 89px;
	position: relative;

}

.Card-small2:nth-child(0) {
	border-left: unset !important;

}

.Card-small.bordernew {
	border: 2px solid #4E9F3D !important;
}

.border-bottom {
	border-bottom: 2px solid #eff2f5 !important;
	width: 94%;
	margin: 0 auto;
}

.topbarInfo {
	color: #fff;
	background-color: #4E9F3D !important;
	width: 20px;
	height: 18px;
	margin-top: 4px;
	position: absolute;
	top: -15px;
	right: 3px;
	border-radius: 3px;
	font-size: 10px;
	font-weight: 600;
	line-height: 18px;
	text-align: center;
}

.Card-small h5,
.Card-small h4.lead_title {
	text-align: center;
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.Card-horizontal {
	/* cursor: pointer; */
	border-left-width: 8px !important;
	/* background-color: #edeff7 !important; */
	border-radius: 5px !important;
	margin-top: 10px;
	padding: 10px;
	border: 1px solid transparent;
}

.card-icon-rounded-small {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card-icon-rounded {
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.invert-img {
	width: 25px;
	filter: invert(1);
}

.aside-enabled.aside-fixed .wrapper {
	background: #e6eafc !important;
	/* overflow: auto; */
}

#kt_post {
	overflow-y: scroll !important;
}

.color-white {
	color: #ffffff !important;
	/* line-height: 10px !important; */
}

.react-tags__search-input {
	width: 80% !important;
}


.react-tags {
	position: relative;
	padding: 6px 0 0 6px;
	border: 1px solid #D1D1D1;
	border-radius: 1px;

	/* shared font styles */
	font-size: 1em;
	line-height: 1.2;

	/* clicking anywhere will focus the input */
	cursor: text;
}

.react-tags.is-focused {
	border-color: #B1B1B1;
}

.react-tags__selected {
	display: inline;
}

.react-tags__selected-tag {
	display: inline-block;
	box-sizing: border-box;
	margin: 0 6px 6px 0;
	padding: 6px 8px;
	border: 1px solid #D1D1D1;
	border-radius: 2px;
	background: #F1F1F1;

	/* match the font styles */
	font-size: inherit;
	line-height: inherit;
}

.react-tags__selected-tag:after {
	content: '\2715';
	color: #AAA;
	margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
	border-color: #B1B1B1;
}

.react-tags__search {
	display: inline-block;

	/* match tag layout */
	padding: 7px 2px;
	margin-bottom: 6px;

	/* prevent autoresize overflowing the container */
	max-width: 100%;
}

@media screen and (min-width: 30em) {

	.react-tags__search {
		/* this will become the offsetParent for suggestions */
		position: relative;
	}

}

.react-tags__search-input {
	/* prevent autoresize overflowing the container */
	max-width: 100%;

	/* remove styles and layout from this element */
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;

	/* match the font styles */
	font-size: inherit;
	line-height: inherit;
}

.react-tags__search-input::-ms-clear {
	display: none;
}

.react-tags__suggestions {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
}

@media screen and (min-width: 30em) {

	.react-tags__suggestions {
		width: 240px;
	}

}

.react-tags__suggestions ul {
	margin: 4px -1px;
	padding: 0;
	list-style: none;
	background: white;
	border: 1px solid #D1D1D1;
	border-radius: 2px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 6px 8px;
}

.react-tags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

.react-tags__suggestions li:hover {
	cursor: pointer;
	background: #eee;
}

.react-tags__suggestions li.is-active {
	background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
	opacity: 0.5;
	cursor: auto;
}

.lead_title {
	font-size: 15px !important;
}

.aside-menu .menu-item .menu-link {
	border-radius: 15px !important;
	margin-left: 10px !important;
}

.text-muted.d-block {
	color: #333 !important;
}

.chatbox {
	padding: 11px;
	background: #f1faff;
	border-radius: 15px;
}

.btnGreen,
.btn-success.btnGreen:hover,
.btnGreen:focus,
.btnGreen:active {
	background-color: #4E9F3D !important;
	border-color: #4E9F3D !important;
}

.btnBlue {
	background-color: #41c4f1 !important;
	border-color: #41c4f1 !important;
	padding: 5px 8px !important;
}
.btnBlack{
	background-color: #000000 !important;
	border-color: #000000 !important;
	padding: 5px 8px !important;

}

.btnDarkBlue {
	background-color: #1f719e !important;
	border-color: #1f719e !important;
	padding: 5px 8px !important;
}

.rowMedium {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
}

.ant-menu-light {
	display: inline-flex;
	list-style-type: none;
}

.lang-section a {
	margin-left: 10px;
	cursor: pointer;
}

.lang-section a img {
	border-radius: 50%;
}

.vid-part {
	margin-top: 20px;
}

.support-section {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	position: static;
    bottom: 0;
}

.support {
	width: 150px;
}

.video {
	/* border-radius: 10px; */
	width: 100%;
}

.vid-play {
	padding: 10px;
}

.camp-title {
	font-weight: 600;
	padding-right: 10px;
}

.camp-status {
	padding-right: 4px;
}

.card-small-icon-rounded {
	border-radius: 50%;
	width: 12px;
	height: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.MEETING-TITLE {
	text-align: center;
	color: #ffffff;
}

.meeting-container {
	background: #41c4f1;
	padding: 45px;
}

.meeting-info {
	font-size: 16px;
	text-align: center;
}

.text-body {
	width: 60%;
	margin: 0 auto;
}

.meeting-container {
	/* position: absolute; */
	top: 10%;
	left: 23%;
	width: 50%;
}

.text {
	display: inline;
	width: 100%;
}

.read-or-hide {
	color: rgb(192, 192, 192);
	cursor: pointer;
}

.comnt {
	letter-spacing: 1px;
}

#background-video {
	border-radius: 10px;
	width: 100%;
	/* height: 100vh; */
	object-fit: cover;
	/* position: fixed; */
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 99999;
}

.login {
	width: 64%;
	margin: 0 auto;
}

.cus-image {
	/* background-image: url('../_metronic//assets/desktop_img.png');
	object-fit: fill;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain; */
	background-image: url('../_metronic//assets/desktop_img.png');
	object-fit: fill;
	height: 100%;
	background-position: unset;
	background-repeat: no-repeat;
	background-size: cover;
}

.blink {
	animation: blink 2s steps(1, end) infinite;
}

@keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.lock-icon {
	width: 20px;
}

.list-header {
	padding: 1rem 1rem !important;
}

.btm-btn {
	display: flex;
	justify-content: flex-end;
	margin-top: 0 !important;
}

.j-center {
	justify-content: center;
}

.round {
	border-radius: 46%;
	/* height: 44px; */
}

.isActive {
	border-bottom: 1px solid #5e6278;
}

.main_div {
	width: 50%;
	/* 
	height: 100vh; */
	/* background: #6983aa; */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.center_div {
	width: 25%;
	height: 40vh;
	background-color: pink;
	border-radius: 5%;
	box-shadow: 5px 5px 20px -5px black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.btn_div {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.todo_style {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
}


.rating-icon {
	background: #34a7ce !important;
	color: white !important;
}

.white-bg {
	background: white;
}

.sales-title {
	text-align: center;
	padding-top: 10px;
}

.vids {
	padding: 0 !important;
}

.crm-image {
	vertical-align: bottom;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
}

.crm-video {
	height: 120px;
}

.comments {
	min-height: 100px !important;
	border-radius: 6px !important;
	border: 1px solid rgb(209, 214, 218) !important;
	box-shadow: rgba(0, 204, 189, 0.2) 0px 0px 0px 0px !important;
	margin-top: 0px;
	margin-bottom: 0px;
	width: 70%;
	padding: 10px;
}

.meeting-text {
	text-align: justify;
}

.qus {
	height: 2px;
	width: 4px;
	background: #009ef7;
	padding: 2px;
	color: #FFFFFF;
	cursor: pointer;
}

.fd_status {
	color: red;
	font-weight: 500;
	font-size: 2rem;
	margin-bottom: 14px;
}

.css-dqr9h-MuiRating-label {
	font-size: 1.2rem;
}

.MuiRating-icon .MuiSvgIcon-root {
	font-size: 3.2rem !important;
}

.rating {
	text-align: center;
	padding-bottom: 20px;
}

.feed_tit {
	padding-top: 10px;
}

.rating-text {
	font-size: 1.2rem;
}

.bottom-container {
	display: none !important;
}

.Component-root-1 {
	/* height: 500px !important; */
}

#bottom-container {
	display: none !important;
}

.border-1 {
	border: 1px solid #e3e3e3 !important;
}

.MuiTab-root {
	min-width: 150px !important;
}

.smsicon {
	width: 18px;
	height: 18px;
	cursor: pointer;
	margin-left: 10px;
}

.status-btn {
	font-size: 10px !important;
}

/* .card-white.upcoming_meets1 {
	max-height: 530px;
	overflow: scroll;
} */

.total-list {
	background: #d63384;
	color: #FFFFFF;
	width: 26px;
	text-align: center;
	border-radius: 4px;
}
.dot-color {
	display: flex;
}
.dot-me{
	margin: 4px 4px 0;
}
.meetingtitle {
	width: 100% !important;
}
.ms1-5 {
	margin-right: 1.1rem;
}
.btn-left {
	justify-content: space-between !important;
}
.btnPink{
	background-color: #ed819d !important;
	font-size: 13px;
    font-weight: 600;
}
.btn1 {
	display: flex;
}
.datetime{
	display: flex;
    justify-content: space-evenly;
	width: 80%;
}
.btn-danger {
	padding: 2px 6px !important;
}
.btn .badge {
    position: relative;
    top: -14px;
    left: 13px;
}